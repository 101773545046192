@media (min-width: 992px) {
    .layout-wrapper {
        &.layout-overlay {
            .layout-main-container {
                margin-left: 0;
                padding-left: 2rem;
            }

            .layout-sidebar {
                transform: translateX(-100%);
                left: 0;
                top: 0;
                height: 100vh;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &.layout-overlay-sidebar-active {
                .layout-sidebar {
                    transform: translateX(0);
                }
            }
        }

        &.layout-static {
            .layout-main-container {
                margin-left: 200px;
            }

            &.layout-static-sidebar-inactive {
                .layout-sidebar {
                    width: 50px;
                    left: 0;
                    .layout-topbar {
                        justify-content: center !important;
                        h5, .logo-img, .border-circle {
                           display: none !important;
                       }
                    }
                .layout-menu-container {
                    ul.layout-menu {
                        text-align: -webkit-center;
                        li ul li a {
                            max-width: fit-content;
                        }
                    }
                    }
                }

                .layout-main-container {
                    margin-left: 0;
                    padding-left: 5rem;
                }

                .layout-menuitem-category ul li a span {
                    display: none;
                }

                li ul:hover {
                    li a i {
                        display: block !important;
                    }
                    p-megamenu {
                        width: fit-content;
                        position: absolute;
                        display: block !important;
                        z-index: 1000 !important;
                        left: 50px !important;
                        margin-top: -2.5rem;
                        background-color: #fffdff;
                        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 1px 4px rgba(0, 0, 0, 0.01);
                        .p-megamenu {
                            padding: 0.6rem 0.4rem;
                            ul {
                                padding-left: unset;
                                li {
                                    width: 100%;
                                    a {
                                        padding: 0.8rem 1rem;
                                        max-width: none !important;
                                        span {
                                            display: block;
                                            margin-left: 0;
                                            text-align: left;
                                            white-space: nowrap;
                                        }
                                    }
                                }
                                li:hover a{
                                    border-radius: 0.5rem;
                                }
                            }
                        }
                    }
                }

            }
        }

        .layout-mask {
            display: none;
        }
    }
}

@media (max-width: 991px) {
    .layout-wrapper {
        .layout-main-container {
            height: calc(100vh - 50px);
            margin-left: 0;
            // padding-left: 2rem;
            .layout-main {
                position: relative;
                .fe-toggle-sidebar {
                    display: inline-block !important;
                    cursor: pointer;
                    position: absolute;
                   margin-top: 0.8rem;
                }
                .p-breadcrumb-container {
                    margin-left: 25px;
                    padding: 0.7rem 0.7rem 0.7rem 0;
                    width: 95%;
                }
            }
        }

        .layout-sidebar {
            transform: translateX(-100%);
            left: 0;
            top: 0;
            height: 100vh;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .layout-mask {
            z-index: 998;
            background-color: var(--mask-bg);

            &.layout-mask-enter-from,
            &.layout-mask-leave-to {
                background-color: transparent;
            }
        }

        &.layout-mobile-sidebar-active {
            .layout-sidebar {
                transform: translateX(0);
                // width: 50%;
                // height: 100vh;
                // background-color: var(--surface-card);
            }

            .layout-mask {
                display: block;
            }
        }
    }

    .body-overflow-hidden {
        overflow: hidden;
    }
}

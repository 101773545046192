.layout-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin-left $transitionDuration;
    padding: 0 1.4rem 1.4rem;
    // margin-top: 56px;
    overflow: auto;
    // height: calc(100vh - 56px);
    height: 100vh;
}

.layout-main {
    flex: 1 1 auto;
}

.layout-footer {
    transition: margin-left $transitionDuration;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    font-size: 10px;
    opacity: 0.4;
    color: var(--text-color);
}

/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import "assets/sass/layout/_variables";
@import "assets/sass/layout/_layout";
@import "assets/sass/overrides/_overrides";
@import "./mixins";

// scrollbar css

::-webkit-scrollbar-thumb {
  background-color: var(--gray-500);
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

::-webkit-scrollbar {
  width: 16px;
}

//toast css
p-toast {
  .p-toast-summary {
    display: none;
  }

  .p-toast-icon-close {
    margin: -5px;
  }

  .p-toast-message-error {
    border-top: 3px solid var(--text-color-error) !important;
  }

  .p-toast-message-success {
    border-top: 3px solid var(--banner-surface-k) !important;
  }

  .p-toast-message-warn {
    border-top: 3px solid rgb(247, 177, 73) !important;
  }

  .p-toast-message-info {
    border-top: 3px solid var(--color-info) !important;
  }

  .p-toast-message {
    border-radius: 0 !important;

    .p-toast-detail {
      margin-top: 0 !important;
    }
  }

  .p-toast-message-icon {
    font-size: unset !important;

    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }
}

// emoji-mart css

emoji-mart .emoji-mart {
  height: 300px;
  overflow: auto;

  .emoji-mart-bar {
    display: none;
  }

  emoji-search .emoji-mart-search {
    &>input {
      width: 100%;
      padding: 0.4rem;
      border: 2px solid gray;
      border-radius: 4px;
      margin-bottom: 5px;
    }

    &>label,
    &>button {
      display: none;
    }
  }

  .emoji-mart-scroll ngx-emoji {
    cursor: pointer;
  }
}

///cdk drag drop css ///
.cdk-drag-preview {
  display: none !important;
}

// tab-menu css
.p-tabmenu .p-tabmenu-nav-container .p-tabmenu-nav-content {
  .p-tabmenuitem {
    border-right: 1px solid var(--banner-surface-h);
  }
}

//table css
p-table .p-datatable {
  .p-sortable-column.p-highlight {
    .p-sortable-column-icon {
      color: var(--primary-color) !important;
    }

    background: #0000000a;
  }

  .p-sortable-column.p-highlight:hover {
    .p-sortable-column-icon {
      color: var(--primary-color) !important;
    }

    background: #0000000a;
    color:var(--primary-color) !important;
  }

  border: 1px solid var(--banner-surface-h);
  border-top: 0;
  border-radius: 0px 0px 5px 5px;

  .p-datatable-wrapper {
    border-bottom: 1px solid var(--banner-surface-h);
    height: calc(100vh - 159px);
  }

  p-paginator.p-element {
    .p-paginator {
      min-height: 46px !important;
      padding: 0 1rem !important;
      border-radius: 0px 0px 5px 5px;

      .p-paginator-pages .p-paginator-page {
        min-width: 2rem;
        height: 2rem;
      }

      .p-dropdown {
        box-sizing: content-box;
        height: 32px;
        margin-right: 0;

        .p-dropdown-label {
          height: 30px;
          padding: 7px 0 0 6px !important;
        }
      }
    }
  }
}

// form css
.p-float-label>label {
  top: 0px;
  background: var(--surface-card);
  padding: 0px 4px;
  font-size: 12px;
  margin-top: -.5rem;
  margin-left: -4px;
  z-index: 10;
}

.p-datatable .p-datatable-thead {
  height: 46px;

  tr>th {
    background-color: var(--table-header-surface);
  }
}

.p-inputtext {
  height: 2.75rem;
  padding: .75rem !important;
}

.p-float-label textarea {
  height: 70px;
}

.required-field::after {
  content: "*";
  position: absolute;
  margin-top: -1px;
  margin-left: 1px;
  color: var(--text-color-error);
  ;

}

// Custom Breakpoints to have variable spacing & font sizes for different screen sizes 
.qb-sm {
  // <768px

  /*Spacing classes*/
  @include spacing-utilities(0.18);
  /*font classes*/
  @include font-sizes(0.32);
}

.qb-md {
  // ≥768px

  /*Spacing classes*/
  @include spacing-utilities(0.20);
  /*font classes*/
  @include font-sizes(0.36);
}

.qb-lg {
  // ≥992px

  /*Spacing classes*/
  @include spacing-utilities(0.22);
  /*font classes*/
  @include font-sizes(0.40);
}

.qb-xl {
  // ≥1200px
  /*Spacing classes*/
  @include spacing-utilities(0.26);
  /*font classes*/
  @include font-sizes(0.44);
}

.qb-xxl {
  // ≥1400px

  /*Spacing classes*/
  @include spacing-utilities(0.30);
  /*font classes*/
  @include font-sizes(0.48);
}

// main Logo
.disabled {
  opacity: 0.5;
}

.temp-logo {
  transform: scale(2);
  width: 106px;
}

// For Dialog
p-dynamicdialog .p-dialog {
  .p-dialog-content {
    padding: unset !important;
  }
}


.p-dialog, p-dynamicdialog .p-dialog {
  min-width: 200px;
  min-height: 150px;
  
  .p-resizable-handle {
    z-index: 10;
  }

  .p-dialog-header {
    padding: 8px 20px 8px 30px;
    border-bottom: 1px solid var(--banner-surface-h);
  }

  .p-dialog-content,
  .p-dialog-custom-content {
    padding: 30px 30px 15px;
  }

  .p-dialog-footer,
  .p-dialog-custom-footer {
    padding: 8px 30px;
    border-top: 1px solid var(--banner-surface-h);

    button {
      margin: 0;
    }
  }

}

// For Headerless Dialogs
.p-dialog-headerless {
  .p-dialog-content {
    padding: 0;
  }
}

// PRIME DESIGN Override
.p-scrollpanel .p-scrollpanel-bar {
  background: #88898b;
}

// Top Bar Context Menu
.layout-topbar-context-menu {
  top: 30px !important;
  left: 133px !important;
  --overlayArrowLeft: 1px !important;
  border-radius: 1rem !important;
  box-shadow: 0 3px 14px -10px rgba(0, 0, 0, 0.2),
    0 -2px 6px 2px rgba(0, 0, 0, 0.14),
    0 5px 15px 3px rgba(0, 0, 0, 0.12) !important;

  .p-overlaypanel-content {
    padding: 0rem;

    .p-listbox-item {
      padding: 0.85rem 0.9rem;
      border-radius: 0.5rem;
    }

    .p-disabled {
      opacity: 9;
    }
  }

  p-listbox {
    ul {
      padding: .5rem !important;
    }
  }
}

// column config Menu
.config-overlayPanel,
.layout-topbar-context-menu {
  .p-overlaypanel-content .p-listbox-item:not(:last-child) {
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 1px solid var(--banner-surface-h);
  }
}

// Button related

.p-button.p-button-success {
  background-color: var(--banner-surface-k) !important;
}

.p-button.p-button-danger {
  background-color: var(--text-color-error) !important;
}


.add-new-button {
  right: 30px;
  bottom: 30px;
  z-index: 5;

  button {
    height: 5rem !important;
    width: 5rem !important;

    .p-button-icon {
      font-size: 2rem !important;
    }
  }
}

.p-button {
  text-transform: capitalize;

  // To justify spinner with text at center
  &[type] {
    justify-content: center;

    span {
      flex: none;
    }
  }

  // Added class for extra large buttons
  &.p-button-xl {
    font-size: 1.25rem;
    padding: 1.3rem 3rem !important;
  }
}

// ******** Custom common classes ***********
.container-xl {
  margin: auto !important;
}

@media (min-width: 1500px) {
  .container-xl {
    max-width: 1500px;
  }

}

body .p-datepicker {
  min-width: 260px !important;
  max-width: 310px !important;
}

@media (max-width: 991px) {
  .p-datatable-wrapper {
    height: calc(100vh - 197px) !important;
  }

  @supports (-webkit-touch-callout: none) {
    .p-datatable-wrapper {
      height: calc(100vh - 230px) !important;
    }

    p-table tbody uikit-empty-state {
      position: fixed !important;
      top: 50% !important;
      left: 25% !important;
    }
  }
}

@media (max-width: 767px) {

  //   .layout-topbar-context-menu {
  //     left: 0px !important;
  //   }
  .border-custom-radius.p-3 {
    padding: 0.61rem 1rem !important;
  }

  .p-breadcrumb-container {
    width: 88% !important;
  }
}

@media screen and (min-width: 765px) {
  .shadow {
    box-shadow: 1px -1px 3px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  }
}

$surfaces: 'a',
  'b',
  'c',
  'd',
  'e';

// Variants of Banner Surfaces
@each $surface in $surfaces {
  .banner-surface-#{$surface} {
    color: var(--banner-text-#{$surface});
    background-color: var(--banner-surface-#{$surface});
  }

  .text-color-#{$surface} {
    color: var(--banner-text-#{$surface});
  }
}

.text-color-red {
  color: var(--text-color-error) !important;
}

.text-color-green {
  color: var(--banner-surface-k) !important;
}

// Badges CSS
.badge {
  font-size: .8rem;

  &.Active,
  &.active,
  &.PAID,
  &.Paid,
  &.paid,
  &.COMPLETED,
  &.Completed,
  &.completed {
    background: var(--bg-color-success);
    color: var(--color-success);
  }

  &.InActive,
  &.inactive,
  &.PARTIAL,
  &.Partial,
  &.partial,
  &.PENDING,
  &.Pending,
  &.pending {
    background: var(--bg-color-warn);
    color: var(--color-warn);
  }

  &.Fraud,
  &.fraud,
  &.SENT,
  &.Sent,
  &.sent {
    background: var(--bg-color-error);
    color: var(--color-error);
  }

  &.POPUP {
    background: var(--bg-color-info);
    color: var(--color-info);
  }
}

:root {
  .p-overlay {
    .p-overlay-content:has(.p-dropdown-items-wrapper) {
      max-width: 75vw;
    }
  }
  .config-overlayPanel {
    --overlayArrowLeft: 158px !important;

    .p-overlaypanel-content {
      padding: 0;
    }
  }

  .action-overlayPanel {
    --overlayArrowLeft: 183px !important;

    .p-overlaypanel-content {
      padding: 0;
    }
  }

  .p-column-filter-overlay-menu {
    p-dropdown .p-dropdown {
      height: 32px !important;

      .p-inputtext {
        height: 2.5rem !important;
        padding: 8px 0 0 10px !important;
      }
    }

    .p-column-filter-buttonbar {
      padding-top: 0 !important;

      button.p-button.p-button-outlined {
        box-shadow: none !important;
        color: var(--text-color-error);
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.spinner-container {
  top: 0;
  left: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  background: transparent;

  .hss-loader {
    position: relative;
    width: 100px;
    height: 100px;
  }

  .hss-loader:before,
  .hss-loader:after {
    content: '';
    border-radius: 50%;
    position: absolute;
    inset: 0;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3) inset;
  }

  .hss-loader:after {
    box-shadow: 0 5px 0 var(--banner-surface-e) inset;
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0)
    }

    100% {
      transform: rotate(360deg)
    }
  }
}

.p-breadcrumb-container {
  padding: 0.7rem 0.7rem 0.7rem 0;
  margin-left: -5px;
  width: 98%;

  p-breadcrumb .p-breadcrumb {
    background-color: unset;
    border: none !important;
    padding: 0 !important;

    ol li {
      min-width: fit-content;

      &>a {
        color: var(--banner-text-a);
        font-size: 14px;

        &:hover {
          color: var(--banner-text-d);
        }

        &:focus {
          background: unset !important;
        }

        .p-menuitem-text {
          color: unset !important;
        }
      }

      chevronrighticon {
        color: var(--banner-text-a);

        .p-icon {
          width: 12px !important;
          height: 12px !important;
        }
      }
    }
  }
}


// Accordion css
p-accordion {
  p-accordiontab {
    .p-accordion-header {
      &-link {
        background: var(--table-header-surface) !important;
        padding: .5rem !important;
      }
    }

    .p-accordion-tab {
      box-shadow: none;
      border: 1px solid var(--banner-surface-h);
    }
  }

  p-accordiontab .p-accordion-tab {
    margin-bottom: .75rem !important;
  }
}

.p-confirm-popup {
  box-shadow: 0 3px 14px -10px rgba(0, 0, 0, 0.2), 0 -2px 6px 2px rgba(0, 0, 0, 0.14), 0 5px 15px 3px rgba(0, 0, 0, 0.12)
}

.fade-animation {
  -webkit-animation: fadeIn 1s ease-in-out;
  -moz-animation: fadeIn 1s ease-in-out;
  -o-animation: fadeIn 1s ease-in-out;
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}